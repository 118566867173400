@import url("https://fonts.googleapis.com/css2?family=Dosis&display=swap");

.Categories {
  width: 100%;
  transition: all 0.7s ease-in;
  background-color: rgb(162, 162, 240);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Categories:hover {
  height: 105%;
  width: 105%;
  background-color: rgb(162, 162, 240);
}

.Categories div p {
  text-transform: uppercase;
  color: rgb(185, 121, 121);
  font-weight: 600;
  line-height: 46px;
  font-size: 30px;
}

.feature {
  display: flex;
  gap: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.slick-track {
  display: flex;
  height: 100%;
  gap: 10px;
}
.sliderproductshow > .slick-slider > .slick-list > .slick-track {
  display: flex;
  width: 100% !important;
  height: 100%;
  gap: 10px;
}
/* .slick-initialized .absolute {
  display: none;
} */

.Trend {
  font-family: Dosis;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
}

.ant-carousel .slick-slider .slick-prev::before {
  content: "";
}

.ant-carousel .slick-slider .slick-next::before {
  content: "";
}
.slick-slider {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
}
.slick-slider .slick-list {
  width: 100%;
  height: fit-content;
  overflow: hidden;
}
.slick-slide > div {
  height: 100%;
  width: inherit;
}
.customprev {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}
.customnext {
  position: absolute;
  top: 45%;
  transform: translateX(-50%);
  right: 0;
  z-index: 1;
}

.column .ant-carousel {
  height: 100%;
}
.column .ant-carousel .slick-list,
.slick-track {
  height: fit-content;
  /* width: fit-content !important; */
}
.column .ant-carousel .slick-list,
.slick-track .slick-slide {
  max-height: 100%;
}
.slider-cust .slider-track {
  width: fit-content;
}
