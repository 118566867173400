.wooden-text {
  font-size: 20px;
  font-weight: bold;
  font-family: Arial, sans-serif;
  text-transform: uppercase;
  color: transparent;
  /* Make the text transparent */
  background-image: url("../assets/download.jpg");
  /* Replace 'wooden_texture.jpg' with your wooden texture image */
  background-size: cover;
  -webkit-background-clip: text;
  /* Use the text as a mask for the background image */
  background-clip: text;
}
.headerlinks {
  cursor: pointer;
}
