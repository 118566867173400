@import url("https://fonts.googleapis.com/css2?family=Dosis&display=swap");
.archive {
  background-color: #f5f5f5;
  width: 100vw;
  height: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.productList {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.filterproductList {
  position: relative;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  max-width: 100vw;
  padding-left: 15px;
}
